import { TagDetail } from './tag-detail';

export interface Inspection {
  inspectionId: number;
  encryptedAssignmentId: string;
  serialNumber: string;
  shortSerialNumber: string;
  stockNumber: string;
  machineStatusId: number;
  machineStatus: string;
  color: string;
  location: string;
  vehicleLocation: string;
  autograde: string;
  departmentName: string;
  customerName: string;
  daysOld: number;
  ymm: string;
  clientName: string;
  externalClientNumber: number;
  postingDate: Date;
  clientId: string;
  make: string;
  departmentId: string;
  model: string;
  modelYear: string;
  issues: string;
  damageTotal: number;
  approvedTotal: number;
  approvedServicesTotal: number;
  inspectionStatus: string;
  hasDamage: boolean;
  rejectReason: string;
  rentalVersionFilePath: string;
  daysInQueue: number;
  rentalAccountName: string;
  daysWithTag: number;
  auctionSiteId: number;
  saleDate: Date;
  legalSaleDate: Date;
  runNumber: string;
  tags: string[];
  tagDetails: TagDetail[];
  digitalQueueStatus: DigitalQueueStatus;
  digitalQueueHoursRemaining: number;
  geofenceName: string;
  geofenceBroadcastDate: Date;
  inspectionType: string;
  mileage: number;
  odoUnit: string;
  inspector: string;
  inspectionDate: Date;
  titleStatus: string;
  titleProcess: string;
  overallNotes: string;
  overallNotesLastUpdateDateTime: Date;
  repNotes: string;
  repNotesLastUpdateDateTime: Date;
  drivable: string;
}

export enum DigitalQueueStatus {
  Overdue,
  AtRisk,
  OnTarget,
}
