/* eslint-disable @typescript-eslint/naming-convention */
export class Vlc {
  static locations = {
    'ADESA Atlanta': 'C2547809-3143-204D-BD2A-7FAD7CEC4456',
    'ADESA Auctions': 'D0B0B4C1-05AD-1940-9656-CA1C1D36567E',
    'ADESA Austin': 'A7B624A6-421F-CF42-9FEF-A8878E473917',
    'ADESA Birmingham': '8B3E3831-06C9-E940-9113-5561C7007F88',
    'ADESA Boise': '2AE22284-3ED6-412D-AF6E-99C6074FDDA0',
    'ADESA Boston': 'DCD9984F-00AA-ED44-9C68-0EA16D057D8E',
    'ADESA Brashers': 'FDFEDCB1-C453-4C74-95BF-4A9AE002E18F',
    'ADESA Buffalo': 'DB17EBFB-D95E-A44D-AEA1-4588CDCC7EB5',
    'ADESA Calgary': '8A40ED9C-55FF-4E82-B3D6-DBE34E3A12BD',
    'ADESA Charlotte': '27B8ED8E-D959-574D-B28F-3A4158EF5FC1',
    'ADESA Chicago': '2601FDA1-4F41-440B-94B5-6191D4141AE4',
    'ADESA Cincinnati/Dayton': '7F26A1FF-81FA-CA44-975D-4C57D10D8FAE',
    'ADESA Cleveland': '2D72EBD8-FA9A-574C-AA90-FE342D927CC1',
    'ADESA Colorado Springs': '18A33F06-7CAB-1341-9D46-51E1C420CDC2',
    'ADESA Concord': 'F0DD679C-8B53-A64A-A894-C108CB18A8FC',
    'ADESA Dallas': '5F4FA396-8C9D-2E44-92F6-23B74996831F',
    'ADESA Des Moines': '0477FD99-B7DD-2A4B-9C13-C1EB61876506',
    'ADESA DevCorp': 'BD839C97-EFC3-4C1A-A740-54195389DCA8',
    'ADESA East Tennessee': '6EAB71EA-3F66-564E-A870-06C93A24DBFC',
    'ADESA Edmonton': 'FB8BD79F-0138-46C8-A550-7CCD08EDA226',
    'ADESA Fargo': '1CCA59C2-179D-EA47-8391-49A0A312A9B1',
    'ADESA Flint': '1F8545BE-435E-421D-9A99-E32B0B0B0CCE',
    'ADESA Fresno': 'C5AB2A0C-9049-448F-B624-32EAC76A861D',
    'ADESA Golden Gate': '50E5E5AF-C1E4-D241-BE8A-35EB385FBDD6',
    'ADESA Halifax': '46494220-975B-4A3B-9ED6-CF56B3B0C72C',
    'ADESA Houston': 'D6AAABCE-2589-4FE3-8929-659FA1A09BB5',
    'ADESA Indianapolis': 'E414D5C3-3D1E-CC44-AF35-6B3C8CB53874',
    'ADESA Jacksonville': 'B3D16F85-7212-534D-8F76-729790E23AB0',
    'ADESA Kansas City': '4471C8AF-E35A-944D-B5DA-005B40229D46',
    'ADESA Kitchener': '4F30093D-1369-4F3A-B555-159F2F8ABCA0',
    'ADESA Knoxville': '68409451-3EC2-004E-A313-A94B10DDE070',
    'ADESA Lansing': '0A39D055-9B0A-494A-9CFE-CDE61A5064F4',
    'ADESA Las Vegas': '99C51DEC-F5E9-4644-B2A1-E936FF8DB64C',
    'ADESA Lexington': '3E5380E8-FB19-A240-B7B0-3157F67D21C5',
    'ADESA Little Rock': 'B314B43E-1D4E-6C42-B2C8-054AC6A81181',
    'ADESA Long Island': '6FA834F7-2966-2044-A90F-0C9F195AE080',
    'ADESA Los Angeles': '56AC1D35-E062-BB4C-B99F-C615280622A5',
    'ADESA Memphis': 'AB4269A0-B129-7D4C-A636-77E1A0FA6A44',
    'ADESA Mercer': 'A57495E5-1950-D34D-B14C-15A8A00421A0',
    'ADESA Mexico': '9689247A-410D-4E98-BC6A-12F447ACFEA0',
    'ADESA Minneapolis': '764DCE2E-6147-7C43-B103-F4A0B2CAF192',
    'ADESA Moncton': 'AF41A875-B8D2-46FC-820F-3AA6E4BD4542',
    'ADESA Montreal': '45265C6D-0B91-4212-816D-F4C2352DB93D',
    'ADESA Nashville': 'C60C93F6-4C90-6642-9D14-2810B831D018',
    'ADESA New Jersey': '11AD8F71-C191-764B-BE67-B6CEB31214D8',
    'ADESA Northwest': '37A003BF-F419-4938-877C-26CB326FCD74',
    'ADESA Ocala': '0E6A2AC0-B0A8-9E43-9B07-DF46F97680F2',
    'ADESA Orlando': '87205E9F-166F-485D-BEA7-FC7F7F37D941',
    'ADESA Ottawa': '969FC35E-52C5-4D5F-AD7C-2D0846689F4F',
    'ADESA PA': 'B4B7ED64-8564-7840-88AF-F9FDE6C72922',
    'ADESA Phoenix': '5AD2D28E-6B6F-6E43-B34E-21E3523EB3B0',
    'ADESA Pittsburgh': '5D39E752-BE06-4C1D-B1E6-E9FBF415BD1B',
    'ADESA Portland': 'A4F4F3BC-E323-4CFB-82A0-3B6B2A83E09A',
    'ADESA Quebec City': '71BEE5DD-25DD-4FF8-B9C5-EC646B91EE19',
    'ADESA Raleigh': 'E516EE60-E1F1-3C48-A6A5-B90F0F0C5380',
    'ADESA Reno': '5E98468E-33D8-42AD-A844-C61F39EA212A',
    'ADESA Sacramento': '0C293D0B-EFDD-CF43-8819-DDC8B13CF482',
    'ADESA Salt Lake': 'ABF4F1B9-3F8E-4D26-8E8C-E6D9EF18DE36',
    'ADESA San Antonio': '3A28A061-3037-8D48-931E-042BCD69603C',
    'ADESA San Diego': 'E0BC935E-EACC-8146-89F1-819AAB708660',
    'ADESA San Jose': '7B952DB2-38AD-4337-AE75-F8C122EF6962',
    'ADESA Sarasota': '74AE161F-B83E-D14F-8365-B27FCF96BC73',
    'ADESA Saskatoon': 'BEC430C0-6912-497D-8250-00E22C5BCAD6',
    'ADESA Seattle': 'BF1F48FA-0AFD-3F46-8B09-C457FB8D5D5A',
    'ADESA Shreveport': 'FC1668E1-413D-D240-85AC-502A02A210F4',
    'ADESA Sioux Falls': 'AB72C887-D448-A44D-8AE3-63500AB0D78A',
    'ADESA St. Johns': '94FCFAB0-593B-460B-8CF5-8734C0994DDE',
    'ADESA St. Louis': '867EB530-9CC0-AD4D-9F07-62CF51DAAE67',
    'ADESA Syracuse': 'DB98255D-32EF-684A-984B-935AEDABA1FD',
    'ADESA Tampa': 'B5842287-A6B1-D949-B9AF-B1231F83AF4B',
    'ADESA Toronto': '2588E4AB-1883-445B-A4CC-268AEBFDBF66',
    'ADESA Tulsa': '6032AEE3-AB2C-6441-916C-AB23616BD932',
    'ADESA Vancouver - Richmond': '5CF51C70-C22D-42D4-9779-2DAA7FE496E1',
    'ADESA Washington DC': 'B3C2A8A7-769C-9F4A-A354-DCEE25D86FE5',
    'ADESA Windsor': '30238EEE-9521-4409-8646-4F11900CDEE6',
    'ADESA Winnipeg': 'A37982B2-1746-4BB8-A00C-CA8D145D8274',
  };
}
