import { AfterViewInit, Component } from '@angular/core';
import { DigitalQueueStatus } from 'src/projects/search-results/src/app/models/inspection';
import {
  CriteriaName,
  InspectionSearchRequest,
  QueueName,
  QueueType,
  SearchCriteria,
  WorkQueueMap,
} from '../../../../projects/search-results/src/app/models/inspection-search-request';
import { ElementScriptLoader } from '../element-loader.module';
import { Vlc } from '../vlc.module';

@Component({
  selector: 'shell-search-results',
  templateUrl: './search-results-demo.component.html',
})
export class SearchResultsDemoComponent implements AfterViewInit {
  element: Element;
  searchRequest: string;
  days = 30;
  locale = 'en-us';
  siteId = '';
  maxRecords = 2000;
  userId = 'nathan.roe';
  vlcs = Vlc.locations;

  constructor() {
    const scriptLoader = new ElementScriptLoader();
    scriptLoader.loadScript('search-results/elements/adis-search-results.js');
  }

  ngAfterViewInit() {
    this.element = document.getElementsByTagName('adis-search-results')[0];
  }

  set stockNumber(value: string) {
    const searchRequest: InspectionSearchRequest = {
      orderBy: 'StockNumber desc',
      includeCancelled: true,
      includeSold: true,
      queueType: QueueType.SearchQueue,
      includeTags: true,
      searchCriteria: [
        {
          criteriaName: CriteriaName.StockNumber,
          criteriaValue: value,
        },
        this.getCheckedOutCriteria(),
      ],
    };

    this.searchRequest = JSON.stringify(searchRequest);
  }

  set vin(value: string) {
    const searchRequest: InspectionSearchRequest = {
      orderBy: 'StockNumber desc',
      includeCancelled: true,
      includeSold: true,
      queueType: QueueType.SearchQueue,
      includeTags: true,
      searchCriteria: [
        {
          criteriaName: CriteriaName.Vin,
          criteriaValue: value,
        },
        this.getCheckedOutCriteria(),
      ],
    };

    this.searchRequest = JSON.stringify(searchRequest);
  }

  set tag(value: string) {
    const searchRequest: InspectionSearchRequest = {
      maxRecords: this.getMaxRecords(this.maxRecords),
      orderBy: 'StockNumber desc',
      includeCancelled: true,
      includeSold: true,
      queueType: QueueType.TagsQueue,
      includeTags: true,
      searchCriteria: [
        {
          criteriaName: CriteriaName.Flag,
          criteriaValue: value,
        },
        this.getCheckedOutCriteria(),
      ],
    };

    this.searchRequest = JSON.stringify(searchRequest);
  }

  set inspectionId(value: string) {
    const searchRequest: InspectionSearchRequest = {
      orderBy: 'StockNumber desc',
      includeCancelled: true,
      includeSold: true,
      queueType: QueueType.SearchQueue,
      includeTags: true,
      searchCriteria: [
        {
          criteriaName: CriteriaName.AssignmentId,
          criteriaValue: value,
        },
        this.getCheckedOutCriteria(),
      ],
    };

    this.searchRequest = JSON.stringify(searchRequest);
  }

  workQueue(phase: number) {
    const searchRequest: InspectionSearchRequest = {
      maxRecords: this.getMaxRecords(this.maxRecords),
      orderBy: 'StockNumber desc',
      includeCancelled: false,
      includeSold: true,
      queueType: QueueType.WorkQueue,
      queueName: WorkQueueMap[phase],
      includeTags: true,
      searchCriteria: [
        {
          criteriaName: CriteriaName.Phase,
          criteriaValue: phase.toString(),
        },
        this.getDateRangeCriteria(this.days),
      ],
    };
    this.addSiteCriteria(searchRequest);

    this.searchRequest = JSON.stringify(searchRequest);
  }

  atRiskExceptionWorkQueue(phase: number) {
    return this.exceptionWorkQueue(phase.toString(), DigitalQueueStatus.AtRisk);
  }

  overdueExceptionWorkQueue(phase: number) {
    return this.exceptionWorkQueue(phase.toString(), DigitalQueueStatus.Overdue);
  }

  private exceptionWorkQueue(phase: string, digitalQueueStatus: DigitalQueueStatus) {
    const searchRequest: InspectionSearchRequest = {
      maxRecords: this.getMaxRecords(this.maxRecords),
      orderBy: 'StockNumber desc',
      includeCancelled: false,
      includeSold: true,
      queueType: QueueType.ExceptionWorkQueue,
      queueName: QueueName.PendingApproval,
      includeTags: true,
      searchCriteria: [
        {
          criteriaName: CriteriaName.Phase,
          criteriaValue: phase,
        },
      ],
      filter: {
        digitalQueueStatus: digitalQueueStatus,
      },
    };
    this.addSiteCriteria(searchRequest);

    this.searchRequest = JSON.stringify(searchRequest);
  }

  rentalEligible(phase: number, flag: string) {
    const searchRequest: InspectionSearchRequest = {
      maxRecords: this.getMaxRecords(this.maxRecords),
      orderBy: 'StockNumber desc',
      includeCancelled: false,
      includeSold: true,
      queueType: QueueType.RentalQueue,
      queueName: QueueName.Eligible,
      includeTags: true,
      searchCriteria: [
        {
          criteriaName: CriteriaName.Phase,
          criteriaValue: phase.toString(),
        },
        {
          criteriaName: CriteriaName.Flag,
          criteriaValue: flag,
        },
        this.getDateRangeCriteria(this.days),
      ],
    };
    this.addSiteCriteria(searchRequest);

    this.searchRequest = JSON.stringify(searchRequest);
  }

  search() {
    this.element.setAttribute('user-id', this.userId);
    this.element.setAttribute('search-request', this.searchRequest);
    this.element.setAttribute('scroll-to-inspection-id', '123456');
  }

  private getCheckedOutCriteria = (value = 'Yes'): SearchCriteria => {
    return {
      criteriaName: CriteriaName.CheckedOut,
      criteriaValue: value,
    };
  };

  private getDateRangeCriteria(days = 30): SearchCriteria {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - days);

    return {
      criteriaName: CriteriaName.CreateDate,
      criteriaValue: startDate.toLocaleDateString('en-US'),
      secondaryValue: endDate.toLocaleDateString('en-US'),
      isExcluded: false,
    };
  }

  private addSiteCriteria(searchRequest: InspectionSearchRequest) {
    if (!this.siteId) {
      return;
    }

    searchRequest.searchCriteria.push(this.getSiteCriteria(this.siteId));
  }

  private getSiteCriteria(siteId: string): SearchCriteria | null {
    if (!siteId || siteId.length === 0) {
      return null;
    }

    return {
      criteriaName: CriteriaName.DepartmentId,
      criteriaValue: this.siteId,
    };
  }

  private getMaxRecords(maxRecords: number): number | undefined {
    if (+maxRecords === 0) {
      return undefined;
    }
    return maxRecords;
  }
}
