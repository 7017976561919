import { AfterViewInit, Component } from '@angular/core';
import { ElementScriptLoader } from '../element-loader.module';

@Component({
  selector: 'shell-mech-inspection',
  templateUrl: './mech-inspection-demo.component.html',
})
export class MechInspectionDemoComponent implements AfterViewInit {
  element: Element;
  inspectionId = 17453877;
  locale = 'en-us';
  issueCount: number;

  constructor() {
    const scriptLoader = new ElementScriptLoader();
    scriptLoader.loadScript('mechanical-inspection/elements/adis-mechanical-inspection.js');
  }

  ngAfterViewInit() {
    this.element = document.getElementsByTagName('adis-mechanical-inspection')[0];
    addEventListener('adis-mech-inspect.loaded', (event: CustomEvent) => {
      this.issueCount = event?.detail?.issueCount;
    });
  }

  load() {
    if (this.inspectionId) {
      this.element.setAttribute('inspectionId', this.inspectionId.toString());
    }
  }

  refresh() {
    if (this.inspectionId) {
      this.element.setAttribute('refreshing', 'true');
    }
  }
}
