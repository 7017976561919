import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CallbackComponent } from './core/callback/callback.component';
import { LoggedInGuard } from './core/guards/logged-in.guard';
import { SearchResultsDemoComponent } from './search-results/search-results-demo.component';
import { ArchivedCrSearchDemoComponent } from './archived-cr-search/archived-cr-search-demo.component';
import { HomeComponent } from './home/home.component';
import { MechInspectionDemoComponent } from './mech-inspection/mech-inspection-demo.component';

const routes: Routes = [
  {
    path: 'callback',
    component: CallbackComponent,
  },
  { path: 'home', component: HomeComponent, canActivate: [LoggedInGuard] },
  { path: 'search-results', component: SearchResultsDemoComponent, canActivate: [LoggedInGuard] },
  { path: 'archived-cr-search', component: ArchivedCrSearchDemoComponent, canActivate: [LoggedInGuard] },
  { path: 'mech-inspection', component: MechInspectionDemoComponent, canActivate: [LoggedInGuard] },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
