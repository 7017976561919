export class ElementScriptLoader {
  loadScript(scriptName: string) {
    const scripts = Array.from(document.getElementsByTagName('script'));
    const hasComponentScript = scripts.some((element) => element.getAttribute('src')?.includes(scriptName));

    if (!hasComponentScript) {
      const script = this.createScriptNode(scriptName);
      this.addNodeToHead(script);
    }
  }

  private createScriptNode(scriptName: string): HTMLScriptElement {
    const node = document.createElement('script');
    node.src = scriptName;
    node.type = 'text/javascript';
    node.async = false;

    return node;
  }

  private addNodeToHead(node: HTMLElement) {
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
