import { DigitalQueueStatus } from './inspection';

export interface InspectionSearchRequest {
  maxRecords?: number;
  orderBy: string;
  includeCancelled: boolean;
  includeSold: boolean;
  queueType: QueueType;
  queueName?: QueueName;
  searchCriteria: SearchCriteria[];
  includeTags: boolean;
  filter?: SearchFilter;
}

export interface SearchCriteria {
  criteriaName: CriteriaName;
  criteriaValue: string;
  secondaryValue?: string;
  isExcluded?: boolean;
}

export interface SearchFilter {
  digitalQueueStatus?: DigitalQueueStatus;
}

export enum CriteriaName {
  AssignmentId = 'AssignmentId',
  StockNumber = 'StockNumber',
  Vin = 'VIN',
  ClientId = 'ClientId',
  DepartmentId = 'DepartmentId',
  Phase = 'Phase',
  Flag = 'Flag',
  CreateDate = 'CreateDate',
  SaleDate = 'SaleDate',
  Tag = 'Tag',
  AllApprovedDamagesRepaired = 'AllApprovedDamagesRepaired',
  CheckedOut = 'CheckedOut',
}

export enum QueueType {
  WorkQueue = 'workQueue',
  ExceptionWorkQueue = 'exceptionWorkQueue',
  RentalQueue = 'rentalQueue',
  SearchQueue = 'searchQueue',
  TagsQueue = 'tagsQueue',
}

export enum QueueName {
  NeedsAttention = 'Needs Attention',
  NeedsInspection = 'Needs Inspection',
  SuggestedRepair = 'Suggested Repair',
  CertEstimates = 'Cert/Estimates',
  PendingApproval = 'Pending Approval',
  PendingRepair = 'Pending Repair',
  SaleReady = 'Sale Ready',

  Eligible = 'Eligible',
  Disputed = 'Disputed',
  TempRejects = 'Temp Rejects',
  PermRejects = 'Perm Rejects',
  PendingDoc = 'Pending Doc',
  NeedsPrinted = 'Needs Printed',
}

export const WorkQueueMap = {
  129: QueueName.NeedsInspection,
  132: QueueName.NeedsAttention,
  134: QueueName.SuggestedRepair,
  135: QueueName.PendingRepair,
  136: QueueName.SaleReady,
  137: QueueName.PendingApproval,
  139: QueueName.CertEstimates,
};
