import { AfterViewInit, Component } from '@angular/core';
import { ElementScriptLoader } from '../element-loader.module';

@Component({
  selector: 'shell-archived-cr-search',
  templateUrl: './archived-cr-search-demo.component.html',
})
export class ArchivedCrSearchDemoComponent implements AfterViewInit {
  element: Element;
  locale = 'en-us';

  constructor() {
    const scriptLoader = new ElementScriptLoader();
    scriptLoader.loadScript('archived-cr-search/elements/adis-archived-cr-search.js');
  }

  ngAfterViewInit() {
    this.element = document.getElementsByTagName('adis-archived-cr-search')[0];
  }
}
