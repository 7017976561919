import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SearchResultsDemoComponent } from './search-results/search-results-demo.component';
import { HomeComponent } from './home/home.component';
import { ArchivedCrSearchDemoComponent } from './archived-cr-search/archived-cr-search-demo.component';
import { MechInspectionDemoComponent } from './mech-inspection/mech-inspection-demo.component';

@NgModule({
  declarations: [AppComponent, HomeComponent, SearchResultsDemoComponent, ArchivedCrSearchDemoComponent, MechInspectionDemoComponent],
  imports: [AppRoutingModule, BrowserModule, CoreModule, FormsModule],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
